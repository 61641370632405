// TrackWorkoutSession.js
import React, { useState, useEffect, useRef } from 'react';
import {
    fetchExercises,
    saveWorkoutSession,
    submitWorkoutSession,
    finishWorkoutSession,
    deleteWorkoutSession,
    fetchSessionExercises,
    fetchExerciseHistory,
    fetchActiveSessionDetails,
    updateActiveSessionDetails,
    fetchExercise,
    fetchMuscleGroups,
    fetchExerciseEquipment,
    fetchTemplateExercises,
    fetchSpecificWorkoutTemplate,
    markAsProgramSession,
    deactivateProgramSession,
    fetchClientExerciseHistory,
    fetchRecentExercises
} from '../../api/apiHandlers';
import { useParams } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateExerciseModal from './CreateExerciseModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExerciseSlideOver from './ExerciseSlideOver';
import ExerciseDetailsModal from './ExerciseDetailsModal';
import {
    faArrowUp,
    faArrowDown,
    faPlus,
    faEllipsis,
    faMinusCircle,
    faPlusCircle,
    faEdit,
    faAdd,
    faClone,
    faInfoCircle,
    faChartLine,
    faFilter,
    faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons';


const defaultExercise = {
    name: '',
    type: '',
    sets: [{ reps: '', weight: '' }],
    inputValue: '',
    tracksTime: false,
    tracksDistance: false,
    tracksWeight: false,
    tracksReps: false
};

const TrackWorkoutSession = () => {
    const [sessionDetails, setSessionDetails] = useState({});
    const [exercises, setExercises] = useState([defaultExercise]);
    const [availableExercises, setAvailableExercises] = useState([]);
    const [clientAvailableExercises, setClientAvailableExercises] = useState([]);
    const { sessionId } = useParams();
    const { clientId } = useParams();
    const navigate = useNavigate();
    const [isSlideOverOpen, setIsSlideOverOpen] = useState(false);
    const [exerciseHistory, setExerciseHistory] = useState([]);
    const [currentSupersetId, setCurrentSupersetId] = useState(1);
    const [newSupersetSize, setNewSupersetSize] = useState(0);
    const [exerciseSearchTerm, setExerciseSearchTerm] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState({});
    const [isCreateExerciseModalOpen, setIsCreateExerciseModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedExerciseId, setSelectedExerciseId] = useState(null);
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedExercise, setSelectedExercise] = useState(null);
    const [muscleGroups, setMuscleGroups] = useState([]);
    const [equipment, setEquipment] = useState([]);
    const [templateExercises, setTemplateExercises] = useState([]);
    const [templateDetails, setTemplateDetails] = useState({});
    const [loadingExercises, setLoadingExercises] = useState(true);
    const [loading, setLoading] = useState(true);
    const [fetchCounter, setFetchCounter] = useState(0);
    const location = useLocation();
    const [didEditExercise, setDidEditExercise] = useState(false);
    const [recentExercises, setRecentExercises] = useState([]);
    const [filteredRecentExercises, setFilteredRecentExercises] = useState([]); //When Applying Filters
    const [showRecentExercises, setShowRecentExercises] = useState(false);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [refreshKey, setRefreshKey] = useState(0);
    let selectedTemplate;
    if (location.state) {
        selectedTemplate = location.state.selectedTemplate;
    }

    // Used when loading a template initially
    const [templateLoadingFlag, setTemplateLoadingFlag] = useState(false);
    const loadingFlagChanged = useRef(0)

    const slideOverStyles = {
        position: 'fixed',
        top: 0,
        right: 0,
        width: '300px',
        height: '100%',
        overflowY: 'auto',
        backgroundColor: 'white',
        boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.5)',
        padding: '20px',
        boxSizing: 'border-box',
        transform: 'translateX(100%)',
        transition: 'transform 0.3s ease-in-out',
    };
    const slideOverOpenStyles = {
        transform: 'translateX(0)',
    };
    const [displayExercises, setDisplayExercises] = useState([]);
    const [filters, setFilters] = useState({
        muscleGroup: '',
        equipment: '',
    });


    useEffect(() => {
        const loadExercisesAndSetLoading = async () => {
            await loadExercises();
            setLoadingExercises(false);
        };

        loadExercisesAndSetLoading();
    }, []);


    // Fetch session details when the component mounts
    useEffect(() => {
        const loadActiveSessionDetails = async () => {
            try {
                const sessionData = await fetchActiveSessionDetails(sessionId);
                setSessionDetails(sessionData);
            }
            catch (error) {
                console.error('Error loading session details:', error);
            }
        };

        loadActiveSessionDetails();

    }, [sessionId, fetchCounter, availableExercises]);



    // Fetch session exercises when the component mounts
    useEffect(() => {
        const loadSessionExercises = async () => {
            try {
                let sessionExercisesData = await fetchSessionExercises(sessionId);

                let exerciseIds = sessionExercisesData.map(exercise => exercise.ExerciseID);
                exerciseIds = [...new Set(exerciseIds)];

                let fetchedExerciseData = await Promise.all(exerciseIds.map(async id => {
                    const data = await fetchExercise(id);
                    return data;
                }));

                sessionExercisesData.sort((a, b) => a.OrderID - b.OrderID);

                const maxSupersetId = sessionExercisesData.reduce((max, curr) => {
                    return (curr.SupersetID && curr.SupersetID > max) ? curr.SupersetID : max;
                }, 0);
                setCurrentSupersetId(maxSupersetId + 1);

                let supersets = {};
                sessionExercisesData.forEach(exercise => {
                    const supersetKey = exercise.SupersetID ? `superset-${exercise.SupersetID}` : `exercise-${exercise.ExerciseID}`;
                    const exerciseKey = `exercise-${exercise.ExerciseID}`;

                    if (!supersets[supersetKey]) {
                        supersets[supersetKey] = {};
                    }

                    if (!supersets[supersetKey][exerciseKey]) {
                        supersets[supersetKey][exerciseKey] = {
                            id: exercise.ExerciseID,
                            name: exercise.Name,
                            type: exercise.Type,
                            sets: [],
                            supersetId: exercise.SupersetID,
                            order: exercise.OrderID,
                        };
                    }

                    supersets[supersetKey][exerciseKey].sets.push({
                        sessionExerciseID: exercise.SessionExerciseID,
                        reps: exercise.Reps,
                        weight: exercise.Weight,
                        setNumber: exercise.SetNumber,
                        time: exercise.Time,
                        distance: exercise.Distance,
                        notes: exercise.Notes || ''
                    });
                });

                let exercisesArray = [];
                Object.values(supersets).forEach(superset => {
                    Object.values(superset).forEach(exercise => {
                        const fetchedExercise = fetchedExerciseData.find(fetchedExercise => fetchedExercise.ExerciseID === exercise.id);
                        if (fetchedExercise) {
                            let tracksReps = fetchedExercise.TracksReps;
                            let tracksWeight = fetchedExercise.TracksWeight;
                            let tracksTime = fetchedExercise.TracksTime;
                            let tracksDistance = fetchedExercise.TracksDistance;

                            exercise.sets.forEach(set => {
                                tracksReps = fetchedExercise.TracksReps ? true : false;
                                tracksWeight = fetchedExercise.TracksWeight ? true : false;
                                tracksTime = fetchedExercise.TracksTime ? true : false;
                                tracksDistance = fetchedExercise.TracksDistance ? true : false;
                            });

                            const exerciseData = {
                                ...exercise,
                                sets: exercise.sets.sort((a, b) => a.setNumber - b.setNumber),
                                inputValue: exercise.name,
                                tracksDistance,
                                tracksReps,
                                tracksTime,
                                tracksWeight
                            };
                            exercisesArray.push(exerciseData);
                        }
                    });
                });

                exercisesArray.sort((a, b) => a.order - b.order);
                setExercises(exercisesArray);

            } catch (err) {
                console.error('Error loading session exercises:', err);
            }
        };

        loadSessionExercises();
    }, [sessionId, refreshKey, availableExercises]);



    // Fetch Template Details if a template is selected
    useEffect(() => {
        const fetchTemplateDetails = async () => {
            try {
                setTemplateLoadingFlag(true);

                const templateId = selectedTemplate;

                // Fetch the template details
                const templateDetails = await fetchSpecificWorkoutTemplate(templateId);
                setTemplateDetails(templateDetails);
                // Set the Description property from templateDetails to sessionDetails
                sessionDetails.Description = templateDetails.Name;
                sessionDetails.SessionNotes = templateDetails.Description;
                await updateActiveSessionDetails(sessionId, sessionDetails);
                await markAsProgramSession(sessionId);
                setFetchCounter(fetchCounter => fetchCounter + 1);




                // Fetch and process the exercises
                const fetchedTemplateExercises = await fetchTemplateExercises(templateId);

                let exerciseIds = fetchedTemplateExercises.map(exercise => exercise.ExerciseID);

                // Remove duplicates
                exerciseIds = [...new Set(exerciseIds)];






                // Check if the fetched exercises are available
                for (const fetchedExercise of fetchedTemplateExercises) {
                    if (!availableExercises.some(availableExercise => availableExercise.ExerciseID === fetchedExercise.ExerciseID)) {
                        const fetchedExerciseDetails = await fetchExercise(fetchedExercise.ExerciseID);
                        availableExercises.push(fetchedExerciseDetails);
                    }
                }

                // Group exercises by ExerciseID and OrderID
                const groupedExercises = fetchedTemplateExercises.reduce((acc, exercise) => {
                    const key = `${exercise.ExerciseID}-${exercise.OrderID}`;
                    if (!acc[key]) {
                        acc[key] = {
                            ...exercise,
                            sets: []
                        };
                    }
                    acc[key].sets.push({
                        sessionExerciseID: exercise.ProgramExerciseID,
                        reps: exercise.Reps,
                        weight: null, // Default to null if no weight data is available
                        setNumber: exercise.SetNumber,
                        time: null, // Default to null if no time data is available
                        distance: null, // Default to null if no distance data is available
                        notes: exercise.Notes,
                    });
                    return acc;
                }, {});

                // Convert grouped exercises to an array and sort by OrderID
                const convertedExercises = Object.values(groupedExercises).map(exercise => {
                    const availableExercise = availableExercises.find(e => e.ExerciseID === exercise.ExerciseID);
                    const tracksDistance = availableExercise ? Boolean(availableExercise.TracksDistance) : false;
                    const tracksReps = availableExercise ? Boolean(availableExercise.TracksReps) : false;
                    const tracksTime = availableExercise ? Boolean(availableExercise.TracksTime) : false;
                    const tracksWeight = availableExercise ? Boolean(availableExercise.TracksWeight) : false;

                    return {
                        id: exercise.ExerciseID,
                        inputValue: availableExercise ? availableExercise.Name : '',
                        name: availableExercise ? availableExercise.Name : '',
                        order: exercise.OrderID,
                        supersetId: exercise.SupersetID,
                        sets: exercise.sets,
                        tracksDistance: tracksDistance,
                        tracksReps: tracksReps,
                        tracksTime: tracksTime,
                        tracksWeight: tracksWeight
                    };
                }).sort((a, b) => a.order - b.order);

                setExercises(convertedExercises);
            } catch (error) {
                console.error('Error fetching or processing exercises:', error);
            } finally {
                setTemplateLoadingFlag(false);
            }
        };

        if (selectedTemplate && !loadingExercises) {
            fetchTemplateDetails(selectedTemplate);
        }

    }, [selectedTemplate, loadingExercises, availableExercises]);




    useEffect(() => {
        const exercisesWithSets = exercises.filter(exercise => exercise.sets && exercise.sets.length > 0);

        if (exercisesWithSets.length > 0) {
            debounceSaveWorkoutSession();
        }
    }, [exercises, templateDetails]);






    // Reload Exercise Library after closing Modal
    async function handleModalClose() {
        await loadExercises();
    }


    useEffect(() => {
        const loadData = async () => {
            try {
                // Concurrently fetch muscle groups and equipment data
                const muscleGroupsPromise = fetchMuscleGroups();
                const equipmentPromise = fetchExerciseEquipment();

                // Wait for muscle groups and equipment data to resolve
                const [muscleGroupsData, equipmentData] = await Promise.all([
                    muscleGroupsPromise,
                    equipmentPromise,
                ]);

                // Set muscle groups and equipment data to state
                setMuscleGroups(muscleGroupsData);
                setEquipment(equipmentData);

            } catch (error) {
                console.error('Error loading data:', error);
            }
        };

        loadData();
    }, []);

    useEffect(() => {
        // Add when the component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Return a function to be called when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);





    // Load Exercises
    const loadExercises = async () => {
        try {
            // Fetch available exercises
            const exercisesData = await fetchExercises(localStorage.getItem('userId'));
            console.log('Exercises:', exercisesData);
            exercisesData.sort((a, b) => a.Name.localeCompare(b.Name));
            setAvailableExercises(exercisesData);

            // Fetch recent exercises
            const recentExercisesData = await fetchRecentExercises(clientId, sessionId);
            console.log('Recent Exercises:', recentExercisesData);

            // Match recent exercises with available exercises
            const matchedRecentExercises = recentExercisesData.map(recentExercise => {
                const matchedExercise = exercisesData.find(exercise => exercise.ExerciseID === recentExercise.ExerciseID);
                if (matchedExercise) {
                    // Include the date from recent exercises
                    return { ...matchedExercise, Date: recentExercise.Date };
                }
                return undefined;
            }).filter(exercise => exercise !== undefined);

            // Group matched recent exercises by date
            const groupedRecentExercises = matchedRecentExercises.reduce((groups, exercise) => {
                const date = new Date(exercise.Date).toLocaleDateString();
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(exercise);
                return groups;
            }, {});

            setRecentExercises(groupedRecentExercises);
            setLoading(false);
        } catch (error) {
            console.error('Error loading exercises:', error);
            setLoading(false);
        }
    };




    // Console log recentExercises
    useEffect(() => {
        console.log('Recent Exercises:', recentExercises);
    }, [recentExercises]);

    // Filter Exercises
    const applyFilters = (exercisesArray) => {
        let filtered = exercisesArray;

        if (filters.muscleGroup) {
            filtered = filtered.filter(exercise => exercise.PrimaryMuscleGroupName === filters.muscleGroup);
        }

        if (filters.equipment) {
            filtered = filtered.filter(exercise => exercise.EquipmentName === filters.equipment);
        }

        if (filters.imported !== "") {
            const importedInt = parseInt(filters.imported);
            if (!isNaN(importedInt)) {
                filtered = filtered.filter(exercise => exercise.Imported === importedInt);
            }
        }

        return filtered;
    };




    useEffect(() => {
        const filteredAvailableExercises = applyFilters(availableExercises);
        setDisplayExercises(filteredAvailableExercises);

        const filteredRecentExercisesObject = {};
        for (const date in recentExercises) {
            filteredRecentExercisesObject[date] = applyFilters(recentExercises[date]);
        }
        setFilteredRecentExercises(filteredRecentExercisesObject);
    }, [filters, availableExercises, recentExercises]);




    const handleMuscleGroupChange = (e) => {
        setFilters(prevFilters => ({ ...prevFilters, muscleGroup: e.target.value }));
    };

    const handleEquipmentChange = (e) => {
        setFilters(prevFilters => ({ ...prevFilters, equipment: e.target.value }));
    };

    const handleImportedChange = (e) => {
        setFilters(prevFilters => ({ ...prevFilters, imported: e.target.value }));
    };






    // Handle exercise selection
    const handleExerciseSelect = (index, selectedExercise) => {
        const newExercises = [...exercises];
        newExercises[index] = {
            ...newExercises[index],
            id: selectedExercise.ExerciseID,
            name: selectedExercise.Name,
            tracksDistance: !!selectedExercise.TracksDistance, // Use double NOT to convert to boolean if necessary
            tracksReps: !!selectedExercise.TracksReps,
            tracksTime: !!selectedExercise.TracksTime,
            tracksWeight: !!selectedExercise.TracksWeight,
            inputValue: selectedExercise.Name
        };
        setExercises(newExercises);
        setIsDropdownVisible({ ...isDropdownVisible, [index]: false }); // Hide the dropdown


        setSelectedExerciseId(selectedExercise.ExerciseID);

    };



    const addExercise = () => {
        const newExercise = {
            ...defaultExercise,
            inputValue: '',
            sets: [{ reps: '', weight: '', time: '', distance: '', notes: '' }], // Include placeholders for all metrics
            order: exercises.length + 1, // Assign the next OrderID
            supersetId: null // Assign a default supersetId
        };


        const updatedExercises = [...exercises, newExercise];

        setExercises(updatedExercises);
    };




    const removeExercise = (index) => {
        const exerciseToRemove = exercises[index];

        const newExercises = exercises.filter((_, i) => i !== index);
        setExercises(newExercises);
    };



    const addSet = (exerciseIndex) => {
        const newExercises = [...exercises];
        if (newExercises[exerciseIndex].sets.length === 0) {
            handleExerciseSelect(exerciseIndex, newExercises[exerciseIndex]);
        }
        newExercises[exerciseIndex].sets.push({ reps: '', weight: '' });
        setExercises(newExercises);
    };

    const removeSet = (exerciseIndex, setIndex) => {
        const newExercises = [...exercises];
        newExercises[exerciseIndex].sets = newExercises[exerciseIndex].sets.filter((_, i) => i !== setIndex);
        setExercises(newExercises);
        debounceSaveWorkoutSession();
    };

    const copySet = (exerciseIndex, setIndex) => {
        const setToCopy = exercises[exerciseIndex].sets[setIndex];
        const newExercises = [...exercises];
        newExercises[exerciseIndex].sets.push({ ...setToCopy });
        setExercises(newExercises);
        debounceSaveWorkoutSession();
    };




    const handleAddNotes = (exerciseIndex, setIndex) => {
        // Retrieve the current notes or an empty string if none exist.
        const currentNotes = exercises[exerciseIndex].sets[setIndex].notes || '';
        const note = prompt("Enter your notes:", currentNotes);
        if (note !== null && note !== currentNotes) { // Proceed only if the prompt wasn't cancelled and there's a change.
            setExercises(prevExercises => {
                const newExercises = [...prevExercises];
                newExercises[exerciseIndex].sets[setIndex].notes = note;
                return newExercises;
            });

            // Debounced save
            debounceSaveWorkoutSession();
        }
    };

    const debounceSaveWorkoutSession = debounce(async () => {
        try {
            const preparedExercises = prepareExercisesForSave();
            await saveWorkoutSession(sessionId, preparedExercises);
            await updateActiveSessionDetails(sessionId, sessionDetails);
        } catch (error) {
            console.error('Error in saving workout session:', error);
        }
    }, 1000);




    // Prepare exercises for saving
    function prepareExercisesForSave() {
        return exercises.map((exercise, index) => ({
            ExerciseID: exercise.id,
            SupersetID: exercise.supersetId || null,  // Include SupersetID, default to null if not present
            OrderID: index + 1,  // Assign OrderID based on the index in the array
            sets: exercise.sets.map((set, setIndex) => ({
                SetNumber: setIndex + 1,  // Assign set number based on the index
                Reps: set.reps,
                Weight: set.weight,
                Time: set.time || null,  // Include Time, allow null
                Distance: set.distance || null,  // Include Distance, allow null
                Notes: set.notes || null  // Include Notes, allow null

            }))
        }));
    }






    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSaving(true); // Set loading state to true at the start of save operation
        try {
            // Your existing logic to save the workout session
            const preparedExercises = prepareExercisesForSave();



            await submitWorkoutSession(sessionId, preparedExercises);



            // Update the session description and session notes using sessionDetails
            await updateActiveSessionDetails(sessionId, sessionDetails);

            // Mark the session as finished
            await finishWorkoutSession(sessionId);

            // Clear local storage
            localStorage.removeItem('workoutSession');

            navigate(`/client-workout-sessions/${clientId}`);

        } catch (error) {
            console.error('Error in saving or finishing workout session:', error);
            // Handle error
        } finally {
            setIsSaving(false); // Set loading state to false at the end of save operation, regardless of success or failure
        }
    };


    const handleDeleteWorkout = () => {
        if (window.confirm('Are you sure you want to delete this workout?')) {
            deleteWorkoutSession(sessionId, clientId)
            deactivateProgramSession(sessionDetails.Date)
                .then(() => {
                    // Clear local storage
                    localStorage.removeItem('workoutSession');
                })
                .catch(err => console.error(err))
                .finally(() => {
                    navigate(`/client-workout-sessions/${clientId}`);
                });
        }
    };



    // Handle Exercise History
    // Toggle the slide-over and load exercise history
    const toggleSlideOver = async (exerciseId) => {
        console.log('!!!!!History button clicked!!!!!!!');

        setIsLoadingHistory(true); // Start loading

        // Immediately toggle the slide-over open/close state
        setIsSlideOverOpen(prevState => !prevState);

        // Load exercise history asynchronously without blocking the UI
        if (!isSlideOverOpen) {
            await loadExerciseHistory(exerciseId);  // No need for await here if rendering is more important
            setIsLoadingHistory(false);  // Stop loading once data is fetched
        }
    };

    // Fetch Exercise History
    const loadExerciseHistory = async (exerciseId) => {
        console.log('Exercise History Loaded@@@@@')
        const exercise = await fetchExercise(exerciseId);

        const matchingExercise = availableExercises.find((ex) => ex.Name === exercise.Name);
        if (matchingExercise) {
            const matchingExerciseName = matchingExercise.Name;

            let history = await fetchClientExerciseHistory(clientId, matchingExerciseName);
            history = history.filter(exercise => exercise.Name.includes(matchingExerciseName));
            setExerciseHistory(history);
        } else {
            return null;
        }
    };

    const groupByDate = (history) => {
        return history.reduce((acc, item) => {
            const date = new Date(item.Date).toLocaleDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(item);
            return acc;
        }, {});
    };

    const groupedHistory = groupByDate(exerciseHistory);


    // Create a new superset
    const handleCreateSuperset = () => {
        const size = prompt("How many exercises to add to the superset?");
        if (size && !isNaN(size) && Number(size) > 0) {
            const supersetSize = Number(size);
            const supersetExercises = Array.from({ length: supersetSize }, (_, i) => ({
                ...defaultExercise,
                supersetId: currentSupersetId, // Use the currentSupersetId
                order: exercises.length + i + 1 // Assign the next OrderID
            }));

            // Add the new superset exercises to the existing ones
            setExercises([...exercises, ...supersetExercises]);
            // Increment the currentSupersetId to ensure the next one is unique
            setCurrentSupersetId(currentSupersetId + 1);
        } else {
            alert("Please enter a valid number.");
        }
    };


    const moveExerciseUp = (index) => {
        // Can't move up the first item
        if (index === 0) return;

        setExercises(prevExercises => {
            const newExercises = [...prevExercises];
            const currentExercise = newExercises[index];
            const prevExercise = newExercises[index - 1];

            // New condition for moving up
            if (currentExercise.supersetId !== null) {

                if (currentExercise.supersetId !== prevExercise.supersetId) {
                    return newExercises;
                } else {
                    // Directly swap the current and previous exercises without removing and inserting
                    [newExercises[index], newExercises[index - 1]] = [newExercises[index - 1], newExercises[index]];
                }
                return newExercises;
            } else {
                // Check whether prevExercise.supersetId is null or not
                if (prevExercise.supersetId === null) {
                    // If the previous exercise is a standalone exercise, swap the order and index
                    [currentExercise.order, prevExercise.order] = [prevExercise.order, currentExercise.order];
                    [newExercises[index], newExercises[index - 1]] = [newExercises[index - 1], newExercises[index]];
                } else {
                    // If exercise is part of a superset
                    const supersetExercises = newExercises.filter(exercise => exercise.supersetId === prevExercise.supersetId);

                    // Find the first exercise within the superset
                    const firstSupersetExercise = supersetExercises.reduce((first, exercise) => {
                        return (first && first.order < exercise.order) ? first : exercise;
                    }, null);

                    // Move the current exercise to the position of the first exercise within the superset
                    currentExercise.order = firstSupersetExercise.order;
                    newExercises.splice(index, 1);
                    newExercises.splice(newExercises.indexOf(firstSupersetExercise), 0, currentExercise);

                    // Update the order of all of the exercises that come before the current exercise
                    newExercises.forEach((exercise, i) => {
                        if (i <= newExercises.indexOf(currentExercise)) {
                            exercise.order = i + 1;
                        }
                    });
                }

                // Update the order of all exercises to match their index
                newExercises.forEach((exercise, i) => {
                    exercise.order = i + 1;
                });
            }

            return newExercises;
        });
    };


    const moveExerciseDown = (index) => {
        // Can't move down the last item
        if (index === exercises.length - 1) return;

        setExercises(prevExercises => {
            const newExercises = [...prevExercises];
            const currentExercise = newExercises[index];
            const nextExercise = newExercises[index + 1];

            // New condition
            if (currentExercise.supersetId !== null) {
                if (currentExercise.supersetId !== nextExercise.supersetId) {
                    return newExercises;
                } else {
                    // Directly swap the current and next exercises without removing and inserting
                    [newExercises[index], newExercises[index + 1]] = [newExercises[index + 1], newExercises[index]];
                }
                return newExercises;
            } else {
                // Log whether nextExercise.supersetId is null or not
                if (nextExercise.supersetId === null) {
                    // If the next exercise is a standalone exercise, swap the order and index
                    [currentExercise.order, nextExercise.order] = [nextExercise.order, currentExercise.order];
                    [newExercises[index], newExercises[index + 1]] = [newExercises[index + 1], newExercises[index]];
                } else {
                    // If exercise is part of a superset
                    const supersetExercises = newExercises.filter(exercise => exercise.supersetId === nextExercise.supersetId);

                    // Find the last exercise within the superset
                    const lastSupersetExercise = supersetExercises.reduce((last, exercise) => {
                        return (last && last.order > exercise.order) ? last : exercise;
                    }, null);

                    // Move the current exercise to the position of the last exercise within the superset
                    currentExercise.order = lastSupersetExercise.order;
                    newExercises.splice(index, 1);
                    newExercises.splice(newExercises.indexOf(lastSupersetExercise) + 1, 0, currentExercise);

                    // Update the order of all of the exercises that come after the current exercise
                    newExercises.forEach((exercise, i) => {
                        if (i >= newExercises.indexOf(currentExercise)) {
                            exercise.order = i + 1;
                        }
                    });
                }

                // Update the order of all exercises to match their index
                newExercises.forEach((exercise, i) => {
                    exercise.order = i + 1;
                });
            }

            return newExercises;
        });
    };



    // Keep track of the last superset ID
    let lastSupersetId = null;


    // Wait for user to stop typing before invoking
    function debounce(func, wait = 1000, immediate) {
        let timeout;
        return function () {
            const context = this, args = arguments;
            const later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            const callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };



    // Handle exercise change
    async function handleSetChange(exerciseIndex, setIndex, field, value) {
        setExercises(prevExercises => {
            const newExercises = JSON.parse(JSON.stringify(prevExercises));
            newExercises[exerciseIndex].sets[setIndex][field] = value;
            return newExercises;
        });

        // Call debounceSaveWorkoutSession after state has been updated
        debounceSaveWorkoutSession();
    }


    // Handle exercise search
    const filterExercises = (inputValue) => {
        let filteredAvailable = availableExercises;
        let filteredRecent = recentExercises;

        // Filter by input value if it's not empty
        if (inputValue.trim()) {
            filteredAvailable = availableExercises.filter(exercise =>
                exercise.Name.toLowerCase().includes(inputValue.toLowerCase())
            );

        }

        // Further filter by selected muscle group, if any
        if (filters.muscleGroup) {
            filteredAvailable = filteredAvailable.filter(exercise => exercise.PrimaryMuscleGroupName === filters.muscleGroup);
            filteredRecent = filteredRecent.filter(exercise => exercise.PrimaryMuscleGroupName === filters.muscleGroup);
        }

        // Filter by selected equipment, if any
        if (filters.equipment) {
            filteredAvailable = filteredAvailable.filter(exercise => exercise.EquipmentName === filters.equipment);
            filteredRecent = filteredRecent.filter(exercise => exercise.EquipmentName === filters.equipment);
        }

        // Filter by imported status, if any
        if (filters.imported !== "") {
            const importedInt = parseInt(filters.imported);
            if (!isNaN(importedInt)) {
                filteredAvailable = filteredAvailable.filter(exercise => exercise.Imported === importedInt);
                filteredRecent = filteredRecent.filter(exercise => exercise.Imported === importedInt);
            }
        }

        setDisplayExercises(filteredAvailable);
        setFilteredRecentExercises(filteredRecent);
    };




    // Handle exercise input change
    function handleExerciseInputChange(exerciseIndex, newValue) {
        // Update the exercise's name and inputValue with the new value from the input field
        let newExercises = [...exercises];
        newExercises[exerciseIndex].name = newValue;
        newExercises[exerciseIndex].inputValue = newValue;
        setExercises(newExercises);

        // Apply filters to the input value
        filterExercises(newValue);
    }





    // Toggle the Modal State
    const toggleCreateExerciseModal = () => {
        setIsCreateExerciseModalOpen(!isCreateExerciseModalOpen);
    };


    const handleUserInteraction = (action) => (event) => {
        // Prevents the click event from firing after a touch event
        if (event.type === 'click' && event.detail === 0) {
            return;
        }
        action(event);
    };



    // Dynamically Generate Input Fields for Exercises
    function renderExerciseInputs(exercise, exerciseIndex, setIndex) {
        return (
            <>
                {/* Render reps input if tracksReps is true */}
                {exercise.tracksReps && (
                    <input
                        type="number"
                        className="set-input"
                        value={exercise.sets[setIndex].reps === 0 ? '' : parseFloat(exercise.sets[setIndex].reps).toString()}
                        onChange={(e) => handleSetChange(exerciseIndex, setIndex, 'reps', e.target.value)}
                        placeholder="Reps"
                    />
                )}

                {/* Render weight input if tracksWeight is true */}
                {exercise.tracksWeight && (
                    <input
                        type="number"
                        className="set-input"
                        value={exercise.sets[setIndex].weight === 0 ? '' : parseFloat(exercise.sets[setIndex].weight).toString()}
                        onChange={(e) => handleSetChange(exerciseIndex, setIndex, 'weight', e.target.value)}
                        placeholder="Weight"
                    />
                )}

                {/* Render time input if tracksTime is true */}
                {exercise.tracksTime && (
                    <input
                        type="number"
                        className="set-input"
                        value={exercise.sets[setIndex].time === 0 ? '' : parseFloat(exercise.sets[setIndex].time).toString()}
                        onChange={(e) => handleSetChange(exerciseIndex, setIndex, 'time', e.target.value)}
                        placeholder="Duration (seconds)"
                    />
                )}

                {/* Render distance input if tracksDistance is true */}
                {exercise.tracksDistance && (
                    <input
                        type="number"
                        className="set-input"
                        value={exercise.sets[setIndex].distance === 0 ? '' : parseFloat(exercise.sets[setIndex].distance).toString()}
                        onChange={(e) => handleSetChange(exerciseIndex, setIndex, 'distance', e.target.value)}
                        placeholder="Distance (meters)"
                    />
                )}

            </>
        );
    }



    // Ellipsis Menu Logic
    const menuRef = useRef();
    // Add a function to toggle the ellipsis menu visibility
    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };
    useEffect(() => {
        // Function to detect click outside
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuVisible(false); // Close the menu
            }
        };

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef, setIsMenuVisible]); // Depend on menuRef and setIsMenuVisible



    // Superset Logic
    const addExerciseToSuperset = (supersetId) => {
        const selectedSuperset = exercises.filter((exercise) => exercise.supersetId === supersetId);

        const maxOrder = Math.max(...selectedSuperset.map(exercise => exercise.order));
        const newExercise = { ...defaultExercise, supersetId: supersetId, sets: [{ reps: '', weight: '', time: '', distance: '', notes: '' }], order: maxOrder + 1 };

        let updatedExercises = [...exercises, newExercise];

        updatedExercises = updatedExercises.map((exercise) => {
            if (exercise.order >= newExercise.order && exercise !== newExercise) {
                return { ...exercise, order: exercise.order + 1 };
            }
            return exercise;
        });

        updatedExercises.sort((a, b) => a.order - b.order);

        setExercises(updatedExercises);
    };



    const moveSupersetUp = (supersetId) => {
        setExercises((prevExercises) => {
            const exercises = [...prevExercises];
            const startIndex = exercises.findIndex(ex => ex.supersetId === supersetId);

            if (startIndex <= 0) {
                return exercises; // Already at the top or not found
            }

            let endIndex = startIndex;
            while (endIndex < exercises.length - 1 && exercises[endIndex + 1].supersetId === supersetId) {
                endIndex++;
            }

            let prevIndex = startIndex - 1;
            if (prevIndex > 0 && exercises[prevIndex].supersetId !== null) {
                while (prevIndex > 0 && exercises[prevIndex - 1].supersetId === exercises[prevIndex].supersetId) {
                    prevIndex--;
                }
            }

            // If the previous exercise is not part of a superset, swap the superset with the previous exercise
            if (exercises[prevIndex].supersetId === null) {
                const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
                exercises.splice(prevIndex, 0, ...superset);
                return exercises;
            }

            // If the previous exercise is part of a superset, move up to the start of the previous superset
            let prevSupersetStartIndex = prevIndex;
            while (prevSupersetStartIndex > 0 && exercises[prevSupersetStartIndex - 1].supersetId === exercises[prevSupersetStartIndex].supersetId) {
                prevSupersetStartIndex--;
            }

            const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
            exercises.splice(prevSupersetStartIndex, 0, ...superset);

            exercises.forEach((exercise, index) => {
                exercise.order = index + 1;
            });

            return exercises;
        });
    };

    const moveSupersetDown = (supersetId) => {
        setExercises((prevExercises) => {
            const exercises = [...prevExercises];
            const startIndex = exercises.findIndex(ex => ex.supersetId === supersetId);

            if (startIndex < 0 || startIndex >= exercises.length - 1) {
                return exercises; // Already at the bottom or not found
            }

            let endIndex = startIndex;
            while (endIndex < exercises.length - 1 && exercises[endIndex + 1].supersetId === supersetId) {
                endIndex++;
            }

            let nextIndex = endIndex + 1;
            if (nextIndex < exercises.length && exercises[nextIndex].supersetId !== null) {
                while (nextIndex < exercises.length - 1 && exercises[nextIndex + 1].supersetId === exercises[nextIndex].supersetId) {
                    nextIndex++;
                }
            }

            // If the next exercise is not part of a superset, swap the superset with the next exercise
            if (exercises[nextIndex] && exercises[nextIndex].supersetId === null) {
                const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
                exercises.splice(nextIndex - superset.length + 1, 0, ...superset);
                return exercises;
            }

            // If the next exercise is part of a superset, move down to the end of the next superset
            let nextSupersetEndIndex = nextIndex;
            while (nextSupersetEndIndex < exercises.length - 1 && exercises[nextSupersetEndIndex + 1].supersetId === exercises[nextSupersetEndIndex].supersetId) {
                nextSupersetEndIndex++;
            }

            const superset = exercises.splice(startIndex, endIndex - startIndex + 1);
            exercises.splice(nextSupersetEndIndex - superset.length + 1, 0, ...superset);

            exercises.forEach((exercise, index) => {
                exercise.order = index + 1;
            });

            return exercises;
        });
    };


    // ============= Exercise Details Modal =============
    const handleSelectExercise = async (event, exercise) => {
        event.preventDefault();

        toggleDetailsModal(); // Toggle the modal visibility

        // Find the full exercise details from the available exercises using exercise.id
        let fullExerciseDetails = availableExercises.find(ex => ex.ExerciseID === exercise.id);
        if (!fullExerciseDetails) {
            // Fetch the exercise details
            fullExerciseDetails = await fetchExercise(exercise.id);
            if (!fullExerciseDetails) {
                console.error("Failed to fetch exercise for id:", exercise.id);
                return;
            }
            // Add a flag to indicate that this exercise was fetched
            fullExerciseDetails.wasFetched = true;
            // Add the fetched exercise to availableExercises
            availableExercises.push(fullExerciseDetails);
        }

        // Transform the exercise object to match the expected structure for the modal
        const transformedExercise = {
            ExerciseID: fullExerciseDetails.ExerciseID,
            Name: fullExerciseDetails.Name,
            Description: fullExerciseDetails.Description || "", // Ensure a default value to avoid null
            EquipmentName: fullExerciseDetails.EquipmentName,
            PrimaryMuscleGroupName: fullExerciseDetails.PrimaryMuscleGroupName,
            AssistingMuscleGroupName: fullExerciseDetails.AssistingMuscleGroupName,
            TracksDistance: fullExerciseDetails.TracksDistance,
            TracksReps: fullExerciseDetails.TracksReps,
            TracksTime: fullExerciseDetails.TracksTime,
            TracksWeight: fullExerciseDetails.TracksWeight,
            TrainerUserID: fullExerciseDetails.TrainerUserID,
            isBilateral: fullExerciseDetails.isBilateral,
            isCardio: fullExerciseDetails.isCardio,
            isUnilateral: fullExerciseDetails.isUnilateral,
            file_url: fullExerciseDetails.file_url,
            wasFetched: fullExerciseDetails.wasFetched || false // Add the wasFetched flag
        };

        setSelectedExercise(transformedExercise); // Set the transformed selected exercise
        setIsDetailsModalOpen(true); // Open the modal
    };



    // Toggle the modal's visibility
    const toggleDetailsModal = async () => {
        // Check if the modal is currently open and a specific exercise is selected
        if (isDetailsModalOpen && selectedExerciseId) {
            // Refresh details of the selected exercise and update the displayed information
        }
        // Toggle the modal state
        setIsDetailsModalOpen(prev => !prev);

    };


    const refreshExerciseDetails = async (exerciseId) => {
        try {
            // Fetch the updated exercise details
            const updatedExerciseDetails = await fetchExercise(exerciseId);

            handleExerciseUpdated(updatedExerciseDetails);

        } catch (error) {
            console.error('Error refreshing exercise details:', error);
        }
    };

    // Ensure that handleExerciseUpdated is ready to receive and process the updated exercise details:
    const handleExerciseUpdated = (updatedExercise) => {
        setExercises((prevExercises) =>
            prevExercises.map((exercise) =>
                exercise.id === updatedExercise.ExerciseID ? { ...exercise, ...updatedExercise, inputValue: updatedExercise.Name } : exercise
            )
        );

        // Refresh the dropdown list or perform other update actions as necessary
        loadExercises(); // Consider whether this is necessary based on your application's needs
    };

    function handleClickOutside(event) {
        if (event.target.closest('.exercise-selector')) {
            // If the click is inside an exercise selector, we find out which one
            const exerciseElement = event.target.closest('.exercise-selector');
            const exerciseId = exerciseElement.getAttribute('data-exercise-index');

            setIsDropdownVisible(prevState => {
                const newState = { ...prevState };
                Object.keys(newState).forEach(key => {
                    // Set all dropdowns to false except the one that's clicked
                    newState[key] = key === exerciseId ? prevState[key] : false;
                });
                return newState;
            });
        } else {
            // Click was outside any exercise selector
            setIsDropdownVisible(prevState => {
                const newState = { ...prevState };
                Object.keys(newState).forEach(key => {
                    newState[key] = false;
                });
                return newState;
            });
        }
    }




    // Rendering
    return (
        <form onSubmit={handleSubmit}>
            <div className="track-workout-container">
                <div className="main-workout-view">
                    <h2 className="header"> {sessionDetails.FirstName} {sessionDetails.LastName} </h2>
                    <div className="session-details">
                        {sessionDetails ? (
                            <>
                                <p><strong>Date:</strong> {new Date(sessionDetails.Date).toLocaleDateString()}</p>
                                <input
                                    value={sessionDetails.Description || ""}
                                    onChange={(e) => {
                                        setSessionDetails({ ...sessionDetails, Description: e.target.value });
                                    }}
                                    placeholder="Session Description..."
                                    className="session-description-input"
                                />
                                <textarea
                                    value={sessionDetails.SessionNotes || ""}
                                    onChange={(e) => {
                                        setSessionDetails({ ...sessionDetails, SessionNotes: e.target.value });
                                    }}
                                    placeholder="Session Notes..."
                                    className="session-notes-input"
                                />
                            </>
                        ) : (
                            <p>Loading session details...</p>
                        )}
                    </div>

                    {exercises.reduce((acc, exercise, exerciseIndex) => {
                        let supersetHeading = null;
                        let isStartOfSuperset = exercise.supersetId && exercise.supersetId !== lastSupersetId;
                        let addExerciseButton = null;
                        let moveSupersetUpButton = null;
                        let moveSupersetDownButton = null;

                        if (isStartOfSuperset) {
                            supersetHeading = <h3 className="superset-header">Superset</h3>;
                            lastSupersetId = exercise.supersetId;

                            addExerciseButton = (
                                <button className="track-workout-button" onClick={(event) => {
                                    event.preventDefault();
                                    addExerciseToSuperset(exercise.supersetId);
                                }}> <FontAwesomeIcon icon={faAdd} /> Add Exercise </button>
                            );

                            moveSupersetUpButton = (
                                <button className="track-workout-button" onClick={(event) => {
                                    event.preventDefault();
                                    moveSupersetUp(exercise.supersetId);
                                }}> Move Superset <FontAwesomeIcon icon={faArrowUp} /></button>
                            );

                            moveSupersetDownButton = (
                                <button className="track-workout-button" onClick={(event) => {
                                    event.preventDefault();
                                    moveSupersetDown(exercise.supersetId);
                                }}>Move Superset <FontAwesomeIcon icon={faArrowDown} /></button>
                            );
                        }

                        const exerciseElement = (
                            <div key={exerciseIndex} className={`exercise-section ${exercise.supersetId ? 'superset-exercise' : ''}`}>
                                {supersetHeading}
                                <div className="superset-controls">
                                    {addExerciseButton}
                                    {moveSupersetUpButton}
                                    {moveSupersetDownButton}
                                </div>
                                <div data-exercise-index={exerciseIndex} className="exercise-selector">
                                    <div className="exercise-input-info-container">
                                        <input
                                            type="text"
                                            placeholder="Type to search exercises..."
                                            value={exercise.name || exercise.inputValue || ''}
                                            className="exercise-search-input"
                                            onChange={(e) => {
                                                handleExerciseInputChange(exerciseIndex, e.target.value);
                                                setIsDropdownVisible({ ...isDropdownVisible, [exerciseIndex]: true });
                                            }}
                                            onFocus={() => {
                                                setIsDropdownVisible({ ...isDropdownVisible, [exerciseIndex]: true });
                                            }}
                                        />
                                        <button className="exercise-mod-buttons" type="button" onClick={(event) => handleSelectExercise(event, exercise)}>
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </button>
                                        <button className="exercise-mod-buttons" type="button" onClick={() => toggleSlideOver(exercise.id)}>
                                            <FontAwesomeIcon icon={faChartLine} />
                                        </button>
                                        <button className="exercise-mod-buttons" type="button" onClick={() => removeExercise(exerciseIndex)}>
                                            <FontAwesomeIcon icon={faMinusCircle} />
                                        </button>
                                    </div>
                                    {isDropdownVisible[exerciseIndex] && (
                                        <div className="exercise-suggestions">
                                            <div className="filter-container">
                                                <div className="filter-dropdown">
                                                    <label>Muscle Group:</label>
                                                    <select
                                                        id="muscle-group-selector"
                                                        name="muscleGroup"
                                                        onChange={handleMuscleGroupChange}
                                                        value={filters.muscleGroup}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <option value="">No filter selected.</option>
                                                        {muscleGroups.map((group) => (
                                                            <option key={group.MuscleGroupID} value={group.MuscleGroupName}>
                                                                {group.MuscleGroupName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="filter-dropdown">
                                                    <label>Equipment:</label>
                                                    <select
                                                        id="equipment-selector"
                                                        name="equipment"
                                                        onChange={handleEquipmentChange}
                                                        value={filters.equipment}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <option value="">No filter selected.</option>
                                                        {equipment.map((item) => (
                                                            <option key={item.EquipmentID} value={item.EquipmentName}>
                                                                {item.EquipmentName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="filter-dropdown">
                                                    <label>Imported:</label>
                                                    <select
                                                        id="imported-selector"
                                                        name="imported"
                                                        onChange={handleImportedChange}
                                                        value={filters.imported}
                                                        onClick={(e) => e.stopPropagation()}
                                                    >
                                                        <option value="">No filter selected.</option>
                                                        <option value="1">Imported</option>
                                                        <option value="0">User Created</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <ul>
                                                <button
                                                    type="button"
                                                    className="toggle-recent-exercises-button"
                                                    onClick={() => {
                                                        setShowRecentExercises(prevState => !prevState);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faClockRotateLeft} />
                                                </button>
                                                {showRecentExercises && (
                                                    <>
                                                        <li className="section-title">Recent Exercises</li>
                                                        {Object.keys(filteredRecentExercises).length > 0 ? (
                                                            Object.entries(filteredRecentExercises).map(([date, exercises]) => {
                                                                const formattedDate = new Date(date).toLocaleDateString('en-US', {
                                                                    weekday: 'long',
                                                                    month: '2-digit',
                                                                    day: '2-digit',
                                                                    year: 'numeric'
                                                                });
                                                                return (
                                                                    <div key={date}>
                                                                        <li className="date-title">{formattedDate}</li>
                                                                        {exercises.map(ex => (
                                                                            <li key={ex.ExerciseID} onClick={() => {
                                                                                handleExerciseSelect(exerciseIndex, ex);
                                                                                setIsDropdownVisible({ ...isDropdownVisible, [exerciseIndex]: false });
                                                                            }}>
                                                                                {ex.Name}
                                                                            </li>
                                                                        ))}
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <li>No recent exercises found</li>
                                                        )}
                                                    </>
                                                )}
                                                <li className="section-title">Exercise Library</li>
                                                {displayExercises.filter(ex => ex.Name.toLowerCase().includes(exercise.inputValue.toLowerCase())).map(ex => (
                                                    <li key={ex.ExerciseID} onClick={() => {
                                                        handleExerciseSelect(exerciseIndex, ex);
                                                        setIsDropdownVisible({ ...isDropdownVisible, [exerciseIndex]: false });
                                                    }}>
                                                        {ex.Name}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}





                                </div>
                                {exercise.sets.map((set, setIndex) => {
                                    return (
                                        <div key={setIndex} className="set-section">
                                            {renderExerciseInputs(exercise, exerciseIndex, setIndex)}
                                            <button className="exercise-mod-buttons" type="button" onClick={() => removeSet(exerciseIndex, setIndex)}>
                                                <FontAwesomeIcon icon={faMinusCircle} />
                                            </button>
                                            <button className="exercise-mod-buttons" type="button" onClick={(() => copySet(exerciseIndex, setIndex))}>
                                                <FontAwesomeIcon icon={faClone} />
                                            </button>
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                className={`exercise-mod-buttons ${set.notes ? 'has-notes' : ''}`}
                                                onClick={() => handleAddNotes(exerciseIndex, setIndex)}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="exercise-action-buttons">
                                    <button className="exercise-mod-buttons" type="button" onClick={() => addSet(exerciseIndex)}>
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </button>
                                    <div className="exercise-move-container">
                                        <button className="exercise-move-buttons" type="button" onClick={() => moveExerciseUp(exerciseIndex)}>
                                            <FontAwesomeIcon icon={faArrowUp} />
                                        </button>
                                        <button className="exercise-move-buttons" type="button" onClick={() => moveExerciseDown(exerciseIndex)}>
                                            <FontAwesomeIcon icon={faArrowDown} />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        );

                        if (isStartOfSuperset) {
                            acc.push(<div key={exerciseIndex} className="superset-container">{[exerciseElement]}</div>);
                        } else {
                            let lastElement = acc[acc.length - 1];
                            if (lastElement && lastElement.props.className === "superset-container" && lastSupersetId === exercise.supersetId) {
                                lastElement.props.children.push(exerciseElement);
                            } else {
                                acc.push(exerciseElement);
                            }
                        }

                        return acc;
                    }, [])}
                </div>
                <div className="track-workout-sticky-buttons">
                    <div>
                        <button className="track-workout-ellipsis" type="button" onClick={toggleMenu}>
                            <FontAwesomeIcon icon={faEllipsis} />
                        </button>
                        {isMenuVisible && (
                            <div className="ellipsis-floating-menu" ref={menuRef}>
                                <button className="track-workout-button" type="button" onClick={() => { toggleCreateExerciseModal(); setIsMenuVisible(false); }}>Create New Exercise</button>
                                <button className="track-workout-button" type="button" onClick={() => { handleCreateSuperset(); setIsMenuVisible(false); }}>Create Superset</button>
                            </div>
                        )}
                    </div>
                    <div className="sticky-center-buttons">
                        {isSaving ? (
                            <progress className="progress-bar" />
                        ) : (
                            <div>
                                <button
                                    className="save-workout-button"
                                    type="submit"
                                    onClick={handleUserInteraction(handleSubmit)}
                                >
                                    Save Workout
                                </button>
                                <button
                                    className="delete-workout-button"
                                    type="button"
                                    onClick={handleUserInteraction(handleDeleteWorkout)}
                                >
                                    Delete Workout
                                </button>
                            </div>
                        )}
                    </div>
                    <button className="add-exercise-button" type="button" onClick={addExercise}>
                        <FontAwesomeIcon icon={faPlus} />
                    </button>
                </div>
            </div>

            <ExerciseSlideOver
                isSlideOverOpen={isSlideOverOpen}
                toggleSlideOver={toggleSlideOver}
                clientId={clientId}
                selectedExerciseId={selectedExerciseId}
                groupedHistory={groupedHistory}
                sessionDetails={sessionDetails}
                isLoadingHistory={isLoadingHistory}  // Pass the loading state to the slide-over
            />

            <CreateExerciseModal
                isCreateExerciseModalOpen={isCreateExerciseModalOpen}
                toggleCreateExerciseModal={toggleCreateExerciseModal}
                onModalClose={handleModalClose}
            />

            <ExerciseDetailsModal
                isDetailsModalOpen={isDetailsModalOpen}
                setDidEditExercise={setDidEditExercise}
                refreshKey={refreshKey}
                setRefreshKey={setRefreshKey}
                toggleDetailsModal={toggleDetailsModal}
                selectedExercise={selectedExercise}
                onModalClose={() => {
                    toggleDetailsModal();
                }}
                onExerciseUpdated={handleExerciseUpdated}
                availableExercises={availableExercises}
            />
        </form>
    );


};


export default TrackWorkoutSession;