import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faArrowLeft, faBook, faBars } from '@fortawesome/free-solid-svg-icons';
import './App.css';

// Components
import LoginScreen from './components/LoginScreen';
import RegisterScreen from './components/RegisterScreen';
import ClientScreen from './components/tracking-components/ClientScreen';
import ClientWorkoutSessions from './components/tracking-components/ClientWorkoutSessions';
import WorkoutSession from './components/tracking-components/WorkoutSession';
import TrackWorkoutSession from './components/tracking-components/TrackWorkoutSession';
import CreateClient from './components/tracking-components/CreateClient';
import ExerciseLibrary from './components/tracking-components/ExerciseLibrary';
import ClientProfile from './components/profile-components/ClientProfile';
import ClientAccountVerification from './components/profile-components/ClientAccountVerification';
import AdminHome from './components/admin-components/AdminHome';
import AdminClientPerformance from './components/admin-components/AdminClientPerformance';
import AdminManageTrainers from './components/admin-components/AdminManageTrainers';
import AdminEmailVerification from './components/admin-components/AdminEmailVerification';
import WorkoutTemplates from './components/workout-template-components/WorkoutTemplates';
import TemplateCreation from './components/workout-template-components/TemplateCreation';
import TemplateExerciseManagement from './components/workout-template-components/TemplateExerciseManagement';
import Template from './components/workout-template-components/Template';
import UpdateTemplate from './components/workout-template-components/UpdateTemplate';
import WorkoutProgramLibrary from './components/workout-program-components/WorkoutProgramLibrary';
import ProgramCreation from './components/workout-program-components/ProgramCreation';
import ProgramSchedule from './components/workout-program-components/ProgramSchedule';
import ProgramDetails from './components/workout-program-components/ProgramDetails';
import ProgramTemplateView from './components/workout-program-components/ProgramTemplateView';
import MasterUserHomepage from './components/master-user-components/MasterUserHomepage';
import MasterClubPerformance from './components/master-user-components/MasterClubPerformance';
import CreateClub from './components/master-user-components/CreateClub';
import MuscleMapping from './components/muscle-mapping/MuscleMapping';
import CreateTemporaryClub from './components/master-user-components/CreateTemporaryClub';
import RegisterProspect from './components/master-user-components/RegisterProspect';
import TokenLogin from './components/master-user-components/TokenLogin';
import TrainerDashboard from './components/TrainerDashboard';
import AppointmentCalendar from './components/scheduling-components/AppointmentCalendar';
import AppointmentForm from './components/scheduling-components/AppointmentForm';
import AppointmentDetails from './components/scheduling-components/AppointmentDetails';

// Components: Buttons
function BackButton() {
  let navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <button onClick={goBack} className="back-button">
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );
}

function LogoutButton() {
  let navigate = useNavigate();

  const handleLogout = () => {
    Object.keys(localStorage).forEach(key => {
      if (key !== 'appVersion') {
        localStorage.removeItem(key);
      }
    });
    navigate('/');
  };

  return (
    <button onClick={handleLogout} className="logout-button">Logout</button>
  );
}

// Components: Menu
function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const userType = localStorage.getItem('userType');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = (event) => {
    if (isOpen && !event.target.closest('.hamburger-menu-container')) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeMenu);
    return () => {
      document.removeEventListener('mousedown', closeMenu);
    };
  }, [isOpen]);

  const menuClass = isOpen ? 'menu-items open' : 'menu-items closed';

  const getMenuLinks = () => {
    switch (userType) {
      case 'admin':
      case 'testAdmin':
        return (
          <>
            <Link to="/admin-components/AdminHome" onClick={toggleMenu}>Club Performance</Link>
            <Link to="/admin-components/AdminClientPerformance" onClick={toggleMenu}>Trainer Performance</Link>
            <Link to="/admin-components/AdminManageTrainers" onClick={toggleMenu}>Manage Trainers</Link>
            <LogoutButton />
          </>
        );
      case 'trainer':
      case 'testTrainer':
        return (
          <>
            <Link to="/trainer-dashboard" onClick={toggleMenu}>Trainer Dashboard</Link>
            <Link to="/clients" onClick={toggleMenu}>Clients</Link>
            <Link to="/exercise-library" onClick={toggleMenu}>Exercise Library</Link>
            <Link to="/workout-templates" onClick={toggleMenu}>Workout Templates</Link>
            <Link to="/workout-programs" onClick={toggleMenu}>Workout Programs</Link>
            <LogoutButton />
          </>
        );
      case 'client':
      case 'testClient':
        const userId = localStorage.getItem('userId');
        return (
          <>
            <Link to={`/client-workout-sessions/${userId}`} onClick={toggleMenu}>Schedule</Link>
            <Link to="/exercise-library" onClick={toggleMenu}>Exercise Library</Link>
            <LogoutButton />
          </>
        );
      case 'masterUser':
        return (
          <>
            <Link to="/master-user-homepage" onClick={toggleMenu}>Master User Homepage</Link>
            <Link to="/create-club" onClick={toggleMenu}>Create Club</Link>
            <Link to="/create-temporary-club" onClick={toggleMenu}>Create Temporary Club</Link>
            <LogoutButton />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="hamburger-menu-container">
      <button onClick={toggleMenu} className="hamburger-icon">
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className={menuClass}>
        {getMenuLinks()}
      </div>
    </div>
  );
}

// Main Component
function Main() {
  const CURRENT_APP_VERSION = 'v1.0 - Build: C0035';
  let location = useLocation();

  useEffect(() => {
    const storedVersion = localStorage.getItem('appVersion');
    if (storedVersion !== CURRENT_APP_VERSION) {
      localStorage.clear();
      localStorage.setItem('appVersion', CURRENT_APP_VERSION);
    }
  }, []);

  useEffect(() => {
    const preventZoom = (e) => {
      if (e.ctrlKey || e.metaKey) {
        e.preventDefault();
      }
    };

    const preventTouchMove = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener('wheel', preventZoom, { passive: false });
    document.addEventListener('keydown', preventZoom, { passive: false });
    document.addEventListener('touchmove', preventTouchMove, { passive: false });

    return () => {
      document.removeEventListener('wheel', preventZoom);
      document.removeEventListener('keydown', preventZoom);
      document.removeEventListener('touchmove', preventTouchMove);
    };
  }, []);

  const isClientScreen = location.pathname.startsWith('/clients');
  const isAdminHome = location.pathname.startsWith('/admin-components/AdminHome');
  const isAdminClientPerformance = location.pathname.startsWith('/admin-components/AdminClientPerformance');
  const isAdminEmailVerification = location.pathname.startsWith('/verify-email');
  const isMasterUserHomepage = location.pathname.startsWith('/master-user-homepage');

  return (
    <>
      {location.pathname !== '/' && (
        <nav className="navbar">
          <div>
            {!isClientScreen && !isAdminHome && !isAdminClientPerformance && !isAdminEmailVerification && !isMasterUserHomepage && <BackButton />}
          </div>
          <HamburgerMenu />
        </nav>
      )}
      <div className="main-content">
        <Routes>
          <Route exact path="/" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/clients" element={<ClientScreen />} />
          <Route path="/client-workout-sessions/:clientId" element={<ClientWorkoutSessions />} />
          <Route path="/workout-session/:sessionId/:clientId" element={<WorkoutSession />} />
          <Route path="/track-workout-session/:sessionId/:clientId" element={<TrackWorkoutSession />} />
          <Route path="/ClientWorkoutSessions" element={<ClientWorkoutSessions />} />
          <Route path="/create-client" element={<CreateClient />} />
          <Route path="/exercise-library" element={<ExerciseLibrary />} />
          <Route path="/client-profile/:clientId" element={<ClientProfile />} />
          <Route path="/admin-components/AdminHome" element={<AdminHome />} />
          <Route path="/admin-components/AdminClientPerformance" element={<AdminClientPerformance />} />
          <Route path="/admin-components/AdminManageTrainers" element={<AdminManageTrainers />} />
          <Route path="/verify-email" element={<AdminEmailVerification />} />
          <Route path="/workout-templates" element={<WorkoutTemplates />} />
          <Route path="/template-creation" element={<TemplateCreation />} />
          <Route path="/select-exercises" element={<TemplateExerciseManagement />} />
          <Route path="/template/:templateId" element={<Template />} />
          <Route path="/edit-template/:templateId" element={<UpdateTemplate />} />
          <Route path="/workout-programs" element={<WorkoutProgramLibrary />} />
          <Route path="/program-creation" element={<ProgramCreation />} />
          <Route path="/program-schedule" element={<ProgramSchedule />} />
          <Route path="/program-details" element={<ProgramDetails />} />
          <Route path="/program-template-view" element={<ProgramTemplateView />} />
          <Route path="/create-password" element={<ClientAccountVerification />} />
          <Route path="/access" element={<TokenLogin />} />

          {/* Master User Routes */}
          <Route path="/master-user-homepage" element={<MasterUserHomepage />} />
          <Route path="/master-club-performance/:clubId" element={<MasterClubPerformance />} />
          <Route path="/create-club" element={<CreateClub />} />
          <Route path="/create-temporary-club" element={<CreateTemporaryClub />} />
          <Route path="/register-prospect" element={<RegisterProspect />} />


          {/* Muscle Mapping Routes */}
          <Route path="/muscle-mapping" element={<MuscleMapping />} />

          {/* Scheduling Routes */}
          <Route path="/trainer-dashboard" element={<TrainerDashboard />} />
          <Route path="/view-appointments" element={<AppointmentCalendar />} />
          <Route path="/create-appointment" element={<AppointmentForm />} />
          <Route path="/edit-appointment/:id" element={<AppointmentForm />} />
          <Route path="/appointment-details/:id" element={<AppointmentDetails />} />

        </Routes>
      </div>
    </>
  );
}

// App Component
function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;
